body {
  font-family: 'Poppins', sans-serif;
  height: 100%;
  position: relative;
  margin: 0;
  margin: 0px;
  position: relative;
  min-height: 100%;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}
html {
  position: relative;
  min-height: 100%;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll;
}
/* Custom mathjax styles because for some reason the font and size wanted to change */
.MJXp-math span {
  font-size: 20px;
  font-family: MJXc-TeX-math-I,MJXc-TeX-math-Ix,MJXc-TeX-math-Iw;
}
.mjx-char {
  font-size: 20px;
  font-family: MJXc-TeX-math-I,MJXc-TeX-math-Ix,MJXc-TeX-math-Iw;
}
.MJXp-script {
  font-size: 10px !important;
}
.MJXc-TeX-main-R {
  font-size: 14px !important;
}